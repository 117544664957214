// import React from "react"
// import useTypewriter from "react-typewriter-hook"
// import styled from "styled-components"

// export default function TypeWriter({ word }) {
//   const typing = useTypewriter(word)
//   return (
//     <React.Fragment>
//       <Cursor> {typing} </Cursor>
//     </React.Fragment>
//   )
// }

import React, { useState, useEffect, useRef } from "react"
import useTypewriter from "react-typewriter-hook"
import styled from "styled-components"

const MagicOcean = [
  "Web developer;",
  "Front-end cook",
  "Problem solver;",
  "Web Enthusiast;",
  "UI architect;",
]

let index = 0

export default function App() {
  const [magicName, setMagicName] = useState("Web...guy")
  const intervalRef = useRef({})
  const name = useTypewriter(magicName)
  useEffect(() => {
    intervalRef.current = setInterval(() => {
      // index = index + 1 > 2 ? 0 : ++index + 1
      index = index > 2 ? 0 : ++index
      setMagicName(MagicOcean[index])
    }, 6000)
    return function clear() {
      clearInterval(intervalRef.current)
    }
  }, [magicName])
  return <Cursor>{name}</Cursor>
}

const Cursor = styled.div`
  display: inline-block;
  position: relative;
  &::after {
    content: "";
    margin: auto;
    position: absolute;
    right: -6px;
    top: -3px;
    width: 3px;
    height: 100%;
    background-color: currentColor;
    animation: cursorAnimation 1.5s step-end infinite;
  }

  @keyframes cursorAnimation {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
`
