import React, { useState } from "react"
import { navigate } from "gatsby-link"
import styled from "styled-components"
import FormInput from "./partial/FormInput"

import Container from "../styles/Container"
import Title from "../styles/Title"
import TitleWrapper from "../styles/TitleWrapper"
import { PrimaryButton } from "../styles/Button"
import { AlertDanger, AlertSuccess } from "../styles/Alert"

import { ExternalUrls } from "./partial/SocialLinks"

export default function SayHi() {
  const { facebook, instagram, linkedin, whatsapp, github } = ExternalUrls

  const [data, setData] = useState({
    name: "",
    email: "",
    topic: "",
    company: "",
    message: "",
    // data: {
    // },
  })

  const [submitting, setSubmitting] = useState(false)
  const [success, setSuccess] = useState(false)
  const [errors, setErrors] = useState(false)

  // Handle Form cleaning
  const handleClean = () => {
    setData({
      name: "",
      email: "",
      topic: "",
      company: "",
      message: "",
    })
  }

  // Handle Form inputs changes
  const handleChange = event => {
    const { name, value } = event.target
    setData({
      ...data,
      [name]: value,
    })
    // console.log(data)
  }

  // Handle Error/success reset
  const resetErrorAndOrSucces = () => {
    setSuccess(false)
    setErrors(false)
  }

  // Handle Form submit
  const handleSubmit = async e => {
    e.preventDefault()
    const { name, email, topic, company, message } = data
    // Set submission indicator...
    setSubmitting(true)
    // Reset errors / messages...
    resetErrorAndOrSucces()
    await fetch("/.netlify/functions/contact", {
      method: "POST",
      body: JSON.stringify({
        name,
        email,
        topic,
        company,
        message,
      }),
    })
      .then(response => {
        // console.log(response)
        setSubmitting(false)
        if (response.status != 200) {
          console.log("Submission failed")
          setErrors(true)
        } else {
          setSuccess(true)
          handleClean()
          console.log("Submission succeded XD")
          // Trigger the Google Ads conversion event
          // gtag("event", "conversion", {
          //   // send_to: "AW-594512729/Z4hbCOrGteMYENmWvpsC",
          //   send_to: "AW-594512729/JbW3CLLnq-QYENmWvpsC",
          //   // event_category: "leads",
          //   // event_label: "YOUR_LEAD_FORM_NAME",
          // })
          gtag("event", "Contact (Form submission https://www.mkhobba.com/)", {
            send_to: "AW-594512729/JbW3CLLnq-QYENmWvpsC", // Replace with your Google Ads conversion ID
            event_category: "Contact", // Replace with an appropriate category
            event_label: "JbW3CLLnq-QYENmWvpsC", // Replace with an appropriate label
          })
        }
      })
      .catch(error => {
        console.log(error)
        setErrors(true)
        setSubmitting(false)
      })
  }

  // function gtag_report_conversion(url) {
  //   var callback = function () {
  //     if (typeof url != "undefined") {
  //       window.location = url
  //     }
  //   }
  //   gtag("event", "conversion", {
  //     send_to: "AW-594512729/Z4hbCOrGteMYENmWvpsC",
  //     event_callback: callback,
  //   })
  //   return false
  // }

  const { name, email, topic, company, message } = data
  return (
    <ContactSection id="contact">
      <Container>
        <TitleWrapper>
          <h2>
            <Title>
              Say Hello{" "}
              {/* <span role="img" aria-label="Emoji icon">
                🙂
              </span> */}
            </Title>
          </h2>
        </TitleWrapper>
        <p>
          Have a project in mind, an idea, a suggestion or an issue you want to
          discuss be my guest.
          {/* , I'm all ears. */}
          <br />
          Let's get your ideas into devices and on to screens.
        </p>
        <form onSubmit={handleSubmit}>
          <Grid>
            <GridItem className="contact-form">
              <FormControl>
                <FormInput
                  placeholder="Your full name"
                  name="name"
                  type="text"
                  required
                  value={name}
                  onChange={handleChange}
                />
              </FormControl>
              <FormControl>
                <FormInput
                  placeholder="Your email"
                  name="email"
                  type="text"
                  required
                  value={email}
                  onChange={handleChange}
                />
              </FormControl>
              <FormControl>
                <FormInput
                  placeholder="Subject"
                  name="topic"
                  type="text"
                  required
                  value={topic}
                  onChange={handleChange}
                />
              </FormControl>
              <FormControl>
                <FormInput
                  placeholder="Your company"
                  name="company"
                  type="text"
                  // required
                  value={company}
                  onChange={handleChange}
                />
              </FormControl>
              <FormControl className="full">
                <FormInput
                  placeholder="Write down a message"
                  name="message"
                  textarea="true"
                  cols="30"
                  rows="3"
                  required
                  value={message}
                  onChange={handleChange}
                />
              </FormControl>
              <FormControl className="full submit">
                <PrimaryButton type="submit">
                  {" "}
                  {submitting ? "Submitting..." : "Send message"}{" "}
                </PrimaryButton>
              </FormControl>
              {/* {success ||
                (errors && (
                ))} */}
              <FormControl className="full">
                {/* Error message */}
                {errors && (
                  <AlertDanger>
                    <p> Oups! An error occured while submitting your form.</p>
                  </AlertDanger>
                )}
                {/* Success message */}
                {success && (
                  <AlertSuccess>
                    <p> Your message has been sent!.</p>
                  </AlertSuccess>
                )}
              </FormControl>
            </GridItem>
            <GridItem className="contact-details">
              <div className="item">
                <h3>
                  <label>Get in touch</label>
                </h3>
                <a href="mailto:maeid.kh@gmail.com">maeid.kh@gmail.com</a>
              </div>
              <div className="item">
                <h3>
                  <label>Social media</label>
                </h3>
                <a href={facebook}>Facebook</a>,{" "}
                <a href={instagram}>Instagram</a>,{" "}
                <a href={linkedin}>Linkedin</a>
              </div>
              {/* <div className="item">
                <h3>
                  <label>Current Location</label>
                </h3>
                <a href="https://www.google.com/maps/place/30%C2%B024'42.2%22N+9%C2%B035'27.8%22W/@30.411863,-9.5931231,17.25z/data=!4m14!1m7!3m6!1s0xdb3b6e9daad1cc9:0xbcf8d0b78bf48474!2sAgadir+80000!3b1!8m2!3d30.4277547!4d-9.5981072!3m5!1s0x0:0x0!7e2!8m2!3d30.411723!4d-9.5910427">
                  Agadir, Morocco
                </a>
              </div> */}
            </GridItem>
          </Grid>
        </form>
      </Container>
    </ContactSection>
  )
}

// Styles
const ContactSection = styled.section`
  position: relative;
  &::before {
    content: "";
    height: 100%;
    width: 50%;
    /* background: #eaf1f6; */
    background: ${props => props.theme.skinnyColor};
    position: absolute;
    /* z-index: -1; */
    right: 0;
    top: 0;
    opacity: 0.7;
    border-radius: 5px;
  }
  & > * {
    position: relative;
  }
`

const Grid = styled.div`
  padding: 3.5rem 0;
  display: grid;
  grid-template-columns: 3fr 1fr;
  grid-gap: 3.5rem;
  @media (max-width: 557px) {
    grid-template-columns: 1fr;
  }
`
const GridItem = styled.div`
  &.contact-form {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    grid-gap: 2.5rem 2rem;
  }
  &.contact-details {
    /* display: flex; */
    /* justify-content: center; */
    /* flex-direction: column; */
    @media (max-width: 557px) {
      width: max-content;
      margin: 0 auto;
    }
    .item {
      &:not(:last-child) {
        margin-bottom: 1.5rem;
      }
      label {
        font-family: var(--font-2);
        font-size: 20px;
        /* font-weight: 700; */
        letter-spacing: 0.4px;
        display: block;
      }
      a {
        &:hover {
          text-decoration: underline;
          color: ${props => props.theme.secondaryColor};
        }
      }
    }
  }
`
const FormControl = styled.div`
  position: relative;
  &.full {
    @media (min-width: 992px) {
      grid-column: 1/3;
    }
  }
  &.submit {
    button {
      display: block;
      margin-left: auto;
    }
  }
  label {
    font-size: 14px;
    position: absolute;
    top: 0.5rem;
    left: 10px;
    /* opacity: 0; */
    transition: var(--transition);
  }
  select,
  textarea,
  input {
    display: block;
    font-family: var(--font-1);
    font-size: 16px;
    font-weight: 500;
    line-height: 1;
    padding: 10px 10px;
    background-color: transparent;
    width: 100%;
    border: 0;
    border-bottom: 2px solid ${props => props.theme.textColor};
    border-radius: 0px;
    outline: none;
    -webkit-appearance: none;
    &::placeholder {
      font-style: italic;
      font-weight: 400;
    }
    &::-webkit-input-placeholder {
      color: transparent;
    }

    &:-moz-placeholder {
      /* Firefox 18- */
      color: transparent;
    }

    &::-moz-placeholder {
      /* Firefox 19+ */
      color: transparent;
    }

    &:-ms-input-placeholder {
      color: transparent;
    }
    /* &:focus { */
    &:valid {
      border-bottom-color: ${props => props.theme.secondaryColor};
      background: rgba(255, 255, 255, 3%);
      outline: none;
      & ~ label {
        left: 0;
        top: -1.2rem;
        /* opacity: 1; */
      }
    }
  }
`
