import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `useStaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `useStaticQuery`: https://www.gatsbyjs.org/docs/use-static-query/
 */

export const Image = ({ src }) => {
  const data = useStaticQuery(graphql`
    query {
      me: file(relativePath: { eq: "me.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 620, maxHeight: 700) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      bitmoji: file(relativePath: { eq: "bitmojis/witcher.png" }) {
        childImageSharp {
          fluid(maxWidth: 700) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      workSample: file(relativePath: { eq: "work/work-sample.png" }) {
        childImageSharp {
          fluid(maxWidth: 700) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      muta: file(relativePath: { eq: "work/muta.png" }) {
        childImageSharp {
          fluid(maxWidth: 700) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      conduitcrm: file(relativePath: { eq: "work/conduitcrm.png" }) {
        childImageSharp {
          fluid(maxWidth: 700) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      gladstone: file(relativePath: { eq: "work/gladstone.png" }) {
        childImageSharp {
          fluid(maxWidth: 700) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      streamingshell: file(relativePath: { eq: "work/streamingshell.png" }) {
        childImageSharp {
          fluid(maxWidth: 700) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      metalivraison: file(relativePath: { eq: "work/metalivraison.png" }) {
        childImageSharp {
          fluid(maxWidth: 700) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      ymany: file(relativePath: { eq: "work/ymany.png" }) {
        childImageSharp {
          fluid(maxWidth: 700) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      ortal: file(relativePath: { eq: "work/ortal.png" }) {
        childImageSharp {
          fluid(maxWidth: 700) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      directpotager: file(relativePath: { eq: "work/directpotager.png" }) {
        childImageSharp {
          fluid(maxWidth: 700) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      destockdesign: file(relativePath: { eq: "work/destockdesign.png" }) {
        childImageSharp {
          fluid(maxWidth: 700) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      inparquet: file(relativePath: { eq: "work/inparquet.png" }) {
        childImageSharp {
          fluid(maxWidth: 700) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      eliquidandco: file(
        relativePath: { eq: "work/eliquidandco-transparent.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 700) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      groupesaoas: file(relativePath: { eq: "work/groupesaoas.png" }) {
        childImageSharp {
          fluid(maxWidth: 700) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      taghazoutgoldenspa: file(
        relativePath: { eq: "work/taghazoutgoldenspa.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 700) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      strapexmaroc: file(relativePath: { eq: "work/strapexmaroc.png" }) {
        childImageSharp {
          fluid(maxWidth: 700) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      maroclogo: file(relativePath: { eq: "work/maroclogo.png" }) {
        childImageSharp {
          fluid(maxWidth: 700) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      lessensdemarrakech: file(
        relativePath: { eq: "work/lessensdemarrakech.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 700) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      golfmaroc: file(relativePath: { eq: "work/golfmaroc.png" }) {
        childImageSharp {
          fluid(maxWidth: 700) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      groupesaoas: file(relativePath: { eq: "work/groupesaoas.png" }) {
        childImageSharp {
          fluid(maxWidth: 700) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      inotecha: file(relativePath: { eq: "work/inotecha.png" }) {
        childImageSharp {
          fluid(maxWidth: 700) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      biinnova: file(relativePath: { eq: "work/biinnova.png" }) {
        childImageSharp {
          fluid(maxWidth: 700) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  // console.log(src)
  switch (src) {
    case "me":
      return (
        <Img
          fluid={data.me.childImageSharp.fluid}
          objectPosition="top center"
        />
      )
    case "witcher":
      return <Img fluid={data.bitmoji.childImageSharp.fluid} />
    case "work-sample":
      return <Img fluid={data.workSample.childImageSharp.fluid} />
    case "muta":
      return <Img fluid={data.muta.childImageSharp.fluid} />
    case "ymany":
      return <Img fluid={data.ymany.childImageSharp.fluid} />
    case "ortal":
      return <Img fluid={data.ortal.childImageSharp.fluid} />
    case "conduitcrm":
      return <Img fluid={data.conduitcrm.childImageSharp.fluid} />
    case "metalivraison":
      return <Img fluid={data.metalivraison.childImageSharp.fluid} />
    case "gladstone":
      return <Img fluid={data.gladstone.childImageSharp.fluid} />
    case "streamingshell":
      return <Img fluid={data.streamingshell.childImageSharp.fluid} />
    case "eliquidandco":
      return <Img fluid={data.eliquidandco.childImageSharp.fluid} />
    case "directpotager":
      return <Img fluid={data.directpotager.childImageSharp.fluid} />
    case "destockdesign":
      return <Img fluid={data.destockdesign.childImageSharp.fluid} />
    case "inparquet":
      return <Img fluid={data.inparquet.childImageSharp.fluid} />
    case "inotecha":
      return <Img fluid={data.inotecha.childImageSharp.fluid} />
    case "biinnova":
      return <Img fluid={data.biinnova.childImageSharp.fluid} />
    case "golfmaroc":
      return <Img fluid={data.golfmaroc.childImageSharp.fluid} />
    case "lessensdemarrakech":
      return <Img fluid={data.lessensdemarrakech.childImageSharp.fluid} />
    case "maroclogo":
      return <Img fluid={data.maroclogo.childImageSharp.fluid} />
    case "strapexmaroc":
      return <Img fluid={data.strapexmaroc.childImageSharp.fluid} />
    case "taghazoutgoldenspa":
      return <Img fluid={data.taghazoutgoldenspa.childImageSharp.fluid} />
    case "groupesaoas":
      return <Img fluid={data.groupesaoas.childImageSharp.fluid} />

    default:
      return <Img fluid={data.workSample.childImageSharp.fluid} />
  }
}
