import React, { Component } from "react"
import styled from "styled-components"
import { isMobile } from "react-device-detect"

import { Item, ItemCaption, ItemDate } from "../styles/Card"
// import dumbImg from "../../images/work.png"
import { Image } from "../utils/image"

import VanillaTilt from "vanilla-tilt"

import SwiperCore, { Navigation, Pagination, Scrollbar } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/swiper-bundle.css"

// Temp imports
import screen_1 from "../images/screens/screen-1.png"
import screen_2 from "../images/screens/screen-2.png"

// install Swiper components
SwiperCore.use([Navigation, Pagination, Scrollbar])

class WorkItem extends Component {
  constructor() {
    super()
    this.state = {
      modalOpen: false,
    }
  }

  componentDidMount() {
    !isMobile && VanillaTilt.init(document.querySelectorAll("[data-tilt]"))
  }

  ShowModal = event => {
    event.preventDefault()
    this.setState({ modalOpen: true })
  }

  HideModal = event => {
    event.preventDefault()
    this.setState({ modalOpen: false })
  }

  render() {
    const { modalOpen } = this.state
    const { title, content, image, url, prodcutionDate } = this.props

    return (
      <>
        <Item target="__blank" to={url} data-tilt onClick={this.ShowModal}>
          {/* <img src={dumbImg} alt="Project Xx" /> */}
          <Image src={image != null ? image : "work-sample"} />
          <ItemCaption>
            <div>
              <h3>{title}</h3>
              <p>{content}</p>
            </div>
            {prodcutionDate && (
              <ItemDate>Production date — {prodcutionDate} </ItemDate>
            )}
          </ItemCaption>
        </Item>
        {/* {modalOpen && <Modal triggerHide={this.HideModal} />} */}
      </>
    )
  }
}

// const Modal = ({ triggerHide }) => {
//   return (
//     <ModalWrapper className="modal" onClick={triggerHide}>
//       <div
//         className="modal__inner"
//         onClick={e => {
//           e.stopPropagation()
//         }}
//       >
//         <button className="modal__close" onClick={triggerHide}>
//           Close
//         </button>
//         <div className="modal__slideshow">
//           <Swiper
//             slidesPerView={1}
//             navigation
//             pagination={{ clickable: true }}
//             scrollbar={{ draggable: true }}
//             onSlideChange={() => console.log("slide change")}
//             onSwiper={swiper => console.log(swiper)}
//           >
//             <SwiperSlide>
//               <div className="modal__slideshow__item">
//                 <img src={screen_2} alt="" />
//               </div>
//             </SwiperSlide>
//             <SwiperSlide>
//               <div className="modal__slideshow__item">
//                 <img src={screen_1} alt="" />
//               </div>
//             </SwiperSlide>
//           </Swiper>
//         </div>
//         <div className="modal__body">
//           <div className="modal__header">
//             <div className="modal__title">Project xXx, eCommerce Xx</div>
//             <ul className="modal__taglist">
//               <li>Html</li>
//               <li>SCSS</li>
//               <li>Gulp</li>
//               <li>Wordpress</li>
//             </ul>
//           </div>
//           <p>
//             Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ullam
//             animi possimus odio magnam harum cupiditate exercitationem dicta
//             quis laborum a. Pariatur voluptate quae in dolorem, similique atque
//             consectetur libero architecto. Lorem ipsum dolor sit amet,
//             consectetur adipisicing elit. Ullam animi possimus odio magnam harum
//             cupiditate exercitationem dicta quis laborum a. Pariatur voluptate
//             quae in dolorem, similique atque consectetur libero architecto.
//           </p>
//           <p>
//             Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ullam
//             animi possimus odio magnam harum cupiditate exercitationem dicta
//             quis laborum a. Pariatur voluptate quae in dolorem, similique atque
//           </p>
//           <p>
//             Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ullam
//             animi possimus odio magnam harum cupiditate exercitationem dicta
//             quis laborum a. Pariatur voluptate quae in dolorem, similique atque
//             consectetur libero architecto. Lorem ipsum dolor sit amet,
//             consectetur adipisicing elit. Ullam animi possimus odio magnam harum
//             cupiditate exercitationem dicta quis laborum a. Pariatur voluptate
//             quae in dolorem, similique atque consectetur libero architecto.
//           </p>
//         </div>
//       </div>
//     </ModalWrapper>
//   )
// }

// Styles
const ModalWrapper = styled.div`
  position: fixed;
  z-index: 99;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.75);
  backdrop-filter: blur(4px);
  .modal__inner {
    position: absolute;
    z-index: 99;
    top: 50%;
    right: 0;
    /* bottom: 0; */
    left: 0;
    transform: translateY(-50%);
    max-height: 85vh;
    width: 1000px;
    max-width: calc(100% - 30px);
    /* overflow-y: auto; */
    border-radius: 4px;
    margin: auto;
    background: #fff;
  }
  .modal__close {
    width: 45px;
    height: 45px;
    border-radius: 100%;
    /* background: #000; */
    color: #fff;
    font-size: 16px;
    text-transform: uppercase;
    position: absolute;
    right: 0;
    bottom: 100%;
  }
  .modal__slideshow {
    /* margin-bottom: 1rem; */
    position: relative;
    .modal__slideshow__item {
      height: 400px;
      overflow: auto;
      img {
        width: 100%;
        /* position: absolute; */
        /* top: 0; */
      }
    }
  }
  .modal__header {
    margin: 0 0 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 10px;
    .modal__title {
      font-size: 32px;
      font-family: var(--font-2);
      font-weight: 700;
    }
    .modal__taglist {
      display: flex;
      align-items: center;
      margin: 0 -3px;
      li {
        margin: 3px;
        border: 1px solid #122328;
        background: #dbe6ed;
        padding: 5px 10px;
        line-height: 1;
        border-radius: 30px;
        font-size: 14px;
      }
    }
  }
  .modal__body {
    padding: 1rem 1.5rem;
    p {
      letter-spacing: 0.3px;
      line-height: 1.5;
      font-weight: 400;
      margin-bottom: 15px;
    }
  }
`

export default WorkItem
