import React from "react"
import { Link } from "gatsby"

import styled from "styled-components"
import { motion } from "framer-motion"
import { isIOS } from "react-device-detect"

import Container from "../styles/Container"

import TypeWriter from "../utils/TypeWriter"
import { Image } from "../utils/image"
// import NoiseBg from "../images/noises.png"
import NoiseBg from "../images/noises-dark-v3.png"
import moon from "../images/static/moon.png"

const Intro = () => {
  // Motion
  const transition = { duration: 0.8, ease: [0.6, -0.05, 0.01, 0.9] }

  return (
    <Introduction>
      <Container className="intro-container">
        <IntroInner>
          <div className="caption">
            <h1>
              <span className="name">Muhamed</span>{" "}
              <span className="divider">|</span>{" "}
              <TypeWriter word="Web developer ;" />
            </h1>
            <span className="quote">
              “Make it work, make it right, make it fast.” &nbsp;
              <small>
                <i>– Kent Beck</i>
              </small>
            </span>
            <p>
              I am a front-end developer, possessing a keen eye for design and
              creating engaging User Interfaces. <br /> By crafting accessible,
              appealing and finely tuned web pages, to bring
              {/* give */} your project and idea to life.
            </p>
          </div>
          <IntroBitmoji>
            <Image src="witcher" />
          </IntroBitmoji>
        </IntroInner>
      </Container>
      <ScrollToContent to="#homepage-content">
        <span>
          {/* Scroll or <br /> <small>die</small> */}
          Scroll
        </span>
      </ScrollToContent>
    </Introduction>
  )
}

// Intro
const Introduction = styled.div`
  background-color: ${props => (props.theme.isDark ? "#000" : "#ececec")};
  border-bottom: 1px solid
    ${props => (props.theme.isDark ? props.theme.darkBorder : "transparent")};
  position: relative;
  overflow: hidden;
  height: 770px;
  max-height: calc(100vh - 200px);
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
  padding: 30px 30px;
  margin: 0 auto 80px;
  @media (max-width: 557px) {
    height: calc(100vh - 47px);
    max-height: inherit;
    padding: 15px 15px;
  }
  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    background-image: url(${NoiseBg});
    background-size: contain;
    animation: movePoz 300s linear infinite;
    opacity: 0.5;
    @media (max-width: 557px) {
      /* animation: none; */
      background-size: 180vw;
      animation-duration: 120s;
    }
  }
  .intro-container {
    position: relative;
    padding-top: 15px;
    padding-bottom: 15px;
    z-index: 2;
    max-width: 1400px;
    @media (max-width: 557px) {
      padding: 5px 5px;
      margin-top: 2rem;
    }
    @media (max-width: 768px) {
      position: static;
      &::before {
        display: none;
      }
    }
    &::before {
      content: "";
      background-image: url(${moon});
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center right;
      width: 85vh;
      height: 85vh;
      position: absolute;
      z-index: -1;
      right: 30px;
      /* top: -30px; */
      transform: rotate(10deg) translate(40%, -50%);
    }
  }
  h1 {
    font-size: 60px;
    margin-bottom: 5px;
    @media (max-width: 557px) {
      font-size: 42px;
      margin-bottom: 15px;
      line-height: 1.1;
      .name {
        display: block;
      }
      .divider {
        display: none;
      }
    }
  }
  .quote {
    display: block;
    font-size: 22px;
    font-weight: 500;
    font-style: italic;
    margin-bottom: 5px;
    color: ${props =>
      props.theme.isDark ? props.theme.textColor : props.theme.secondaryColor};
    @media (max-width: 557px) {
      font-size: 20px;
      /* text-align: center; */
      margin-bottom: 20px;
    }
  }
  p {
    line-height: 1.6;
  }
  @keyframes movePoz {
    0% {
      background-position-x: 0;
    }
    100% {
      background-position-x: -1000%;
    }
  }
  @keyframes ScaleCircle {
    0% {
      transform: translate(-50%, -50%) scale(1);
    }
    50% {
      transform: translate(-50%, -50%) scale(1.2);
    }
    100% {
      transform: translate(-50%, -50%) scale(1);
    }
  }
`
const IntroInner = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  position: relative;
  @media (max-width: 557px) {
    display: block;
    position: static;
  }
  .caption {
    display: block;
    text-align: left;
    color: ${props =>
      props.theme.isDark ? props.theme.snowWhite : props.theme.secondaryColor};
    backdrop-filter: blur(1.5px);
    mix-blend-mode: difference;
    @media (max-width: 557px) {
      mix-blend-mode: unset;
      transform: translateY(-20%);
    }
  }
`
const IntroBitmoji = styled.div`
  width: 400px;
  border-radius: 100%;
  background: ${props => (props.theme.isDark ? props.theme.shade : "#000")};
  border: 2px solid
    ${props => (props.theme.isDark ? props.theme.darkBorder : "transparent")};
  /* background: #000; */
  overflow: hidden;
  position: absolute;
  top: 50%;
  right: 0%;
  transform: translateY(-50%);
  z-index: -1;
  opacity: 0.65;
  filter: grayscale(1);
  transition: 0.8s;
  animation: BitmojiMoves 2.5s infinite;
  @media (max-width: 557px) {
    /* width: auto; */
    top: auto;
    bottom: 0vh;
    right: 50%;
    transform: translateX(50%);
    animation: none;
    height: auto;
    border-radius: 0;
    border: 0;
    opacity: 0.2;
  }
  &::after {
    content: "";
    width: 100%;
    height: 100%;
    background: #ffffff6b;
    position: absolute;
    left: 0;
    top: 0;
    transition: 0.8s;
    border-radius: 50%;
    transform-origin: center calc(100% - 65px);
    /* transform: scale(.2); */
    filter: blur(20px);
    animation: BitmojiAfterMoves 3s infinite;
    @media (max-width: 557px) {
      opacity: inherit.4;
    }
  }
  .vintage-svg__outer {
    &::before {
      background: transparent;
    }
  }
  img {
    filter: ${isIOS ? "none" : "url(#filter)"};
  }
  @keyframes BitmojiMoves {
    0% {
      opacity: 0.7;
      transform: translateY(calc(-50% - (-15px)));
    }
    50% {
      /* transform: translateY(-50%); */
      transform: translateY(calc(-50% - (-5px)));
      opacity: 1;
    }
    100% {
      opacity: 0.7;
      transform: translateY(calc(-50% - (-15px)));
    }
  }
  @keyframes BitmojiAfterMoves {
    0% {
      transform: scale(0.75);
    }
    50% {
      transform: scale(0.2);
    }
    100% {
      transform: scale(0.75);
    }
  }
`
const ScrollToContent = styled(Link)`
  font-size: 14px;
  text-align: center;
  color: ${props => props.theme.secondaryColor};
  font-weight: ${props => !props.theme.isDark && "600"};
  letter-spacing: 0.5px;
  position: absolute;
  z-index: 2;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  filter: ${isIOS ? "none" : "url(#filter)"};
  &::after {
    display: block;
    content: "";
    height: 100px;
    width: 2px;
    background: ${props => props.theme.secondaryColor};
    animation: ScrollMove 1.5s infinite;
  }
  span {
    line-height: 1.2;
    margin-bottom: 5px;
    small {
      /* text-decoration: line-through; */
      color: red;
      font-size: 16px;
    }
  }
  @keyframes ScrollMove {
    0% {
      height: 80px;
    }
    50% {
      height: 100px;
    }
    100% {
      height: 80px;
    }
  }
`

export default Intro
