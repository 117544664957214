import styled from "styled-components"

const ItemList = styled.div`
  /* display: grid; */
  grid-template-columns: repeat(auto-fill, minmax(290px, 1fr));
  grid-gap: 60px;
  margin-top: 1rem;
  padding-top: 1rem;
  user-select: none;
  overflow: hidden;
  @media (max-width: 1280px) {
    padding-left: 24px;
  }
  /* Fix for Swiper scroll bar appearance */
  padding-bottom: 2rem;
  margin-bottom: -2rem;
`

export default ItemList
