import React, { Component } from "react"
import { isMobile } from "react-device-detect"

import { Item } from "../styles/Card"
import { ItemCaption } from "../styles/Card"
// import dumbImg from "../../images/work.png"
import { Image } from "../utils/image"

import VanillaTilt from "vanilla-tilt"

class BlogPost extends Component {
  componentDidMount() {
    !isMobile && VanillaTilt.init(document.querySelectorAll("[data-tilt]"))
  }
  render() {
    const { title, content } = this.props
    return (
      <Item data-tilt to="/">
        <Image src="work-sample" />
        <ItemCaption>
          <h3>{title}</h3>
          <p>{content}</p>
        </ItemCaption>
      </Item>
    )
  }
}

export default BlogPost
