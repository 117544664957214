import styled from "styled-components"

const Alert = styled.div`
  font-size: 14px;
  text-align: center;
  padding: 0.8rem 1rem;
  background: rgb(176 156 92 / 10%);
  backdrop-filter: blur(6px);
  border: 2px solid #f0a12924;
  border-radius: 3px;
  margin: 1rem 0;
`
const AlertDanger = styled(Alert)`
  background: rgb(249, 76, 76, 0.07);
  border: 2px solid rgb(255, 67, 67, 0.2);
  color: #af7474;
`
const AlertSuccess = styled(Alert)`
  background: rgb(111, 255, 111, 0.08);
  border: 2px solid rgb(61, 255, 99, 0.2);
  color: #a4c3a4;
`

export { Alert, AlertSuccess, AlertDanger }
