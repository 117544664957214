import styled from "styled-components"
import { Link } from "gatsby"

const LearnMore = styled(Link)`
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.3px;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
  padding: 10px 0;
  /* color: #000; */
  display: none;

  &::before {
    content: "";
    height: 1px;
    width: 30px;
    background: currentColor;
    margin-right: 5px;
    display: inline-block;
    transition: var(--transition);
    transform-origin: right;
    transform: scaleX(2);
  }
  &:hover {
    color: ${props => props.theme.secondaryColor};
    &::before {
      transform: scaleX(1);
    }
  }
`

export default LearnMore
