import React from "react"
import styled from "styled-components"
import { isIOS } from "react-device-detect"

import Container from "../styles/Container"
import Title from "../styles/Title"
import LearnMore from "../styles/LearnMore"
import TitleWrapper from "../styles/TitleWrapper"
import Icon from "../utils/Icons"

export default function Services() {
  return (
    <>
      <section id="skills">
        <Container>
          <TitleWrapper>
            <h2>
              <Title>
                Skills and values
                {/* <span role="img" aria-label="Emoji icon">
                😃
              </span> */}
              </Title>
            </h2>
            <LearnMore to="/">Learn more</LearnMore>
          </TitleWrapper>
          <p>
            {/* Lorem ipsum dolor sit amet consectetur adipisicing elit. Ea tenetur
          praesentium dicta. <br />
          Qos beatae minus, accusantium totam quo! */}
            {/* Front-end development is not only about building the user interface
          that functions.  */}
            Modern web development requires developers to adopt several concepts
            and tasks in order to build websites and web apps that smoothly work
            and adapt with the evolving browsers and devices capabilities.
            Therefore, I am aware that my job as a front-end developer must
            respect the following principles :
          </p>

          <QualitiesList>
            <QualitiesListItem>
              <span className="icon">
                <Icon name="icon-code" />
              </span>
              <h3 className="title">Clean/safe code & DRY</h3>
              <p>
                DRY aka Dont Repeat yourself, is an approach that aims, to turn
                repeated code or functions that serves the same goal, into
                reausable components or functions that can be executed as many
                times as desired, which gives developers the ability to maintain
                the codebase easily with lower possibilities of a website crashe
                or down time.
              </p>
            </QualitiesListItem>

            <QualitiesListItem>
              <span className="icon">
                <Icon name="icon-flash" />
              </span>
              <h3 className="title">Performance in mind</h3>
              <p>
                Website performance optimization, the focal point of
                technologically superior website designs is the primary factor
                dictating success for modern online businesses. After all,
                unimpressive website performance kills a businesses’ bottom line
                when the torture of waiting for slow Web pages to load
                frustrates visitors into seeking alternatives
                <br /> – impatience is a digital virtue!
              </p>
            </QualitiesListItem>

            <QualitiesListItem>
              <span className="icon">
                <Icon name="icon-stack" />
              </span>
              <h3 className="title">Build for mobile first</h3>
              <p>
                According to a 2018 study by{" "}
                <a href="https://www.oberlo.com/">Oberlo</a>, 52.2% of global
                web traffic came from mobile phones, up from just 0.7% in 2009.
                meaning that a website not well optimized aka responsive for
                mobile devices is losing out on approximately half of its
                traffic. Thus adopting responsive deisgn approach to build
                mobile-fiendly websites is a must.
              </p>
            </QualitiesListItem>

            <QualitiesListItem>
              <span className="icon">
                <Icon name="icon-eye" />
              </span>
              <h3 className="title">Keen eye for details</h3>
              <p>
                Being a front-end developer, means that dealing with details is
                a daily basis experience. Thus I do take care of small and
                detailer stuff in the browser interface, because I also believe
                that small things make big difference. <br />
                <code>#small_things_matter</code>
              </p>
            </QualitiesListItem>

            {/* <QualitiesListItem>
            <span className="icon">
              <Icon name="icon-stack" />
            </span>
            <h3 className="title">Refactoring & implementing</h3>
            <p>
              Lorem ipsum dolor sit amet consectetur adipis icing elit. Nemo
              quidem repudiandae nam vero libero voluptate maiores,
              exercitationem distinctio facilis, in eiu.
            </p>
          </QualitiesListItem> */}

            <QualitiesListItem>
              <span className="icon">
                <Icon name="icon-users" />
              </span>
              <h3 className="title">Communication, the key</h3>
              <p>
                Effective two-way communication between managers, designers and
                developers is a crucial process that can make-or-break the
                company's success. Hence I strongly believe that every
                developer, manager and designer should be a team player in the
                work life cycle.
              </p>
            </QualitiesListItem>
          </QualitiesList>
        </Container>
      </section>
      <section id="services">
        <Container>
          <TitleWrapper>
            <h2>
              <Title>Services</Title>
            </h2>
            <LearnMore to="/">Learn more</LearnMore>
          </TitleWrapper>
          {/* <p>
            Lorem ipsum dolor sit, amet consectetur adipisicing elit. Saepe
            laborum beatae inventore praesentium recusandae quidem ducimus. Ab
            numquam debitis assumenda, delectus dignissimos ratione ut
            accusantium, totam dolorum cupiditate aut commodi.
          </p> */}
          <QualitiesList>
            {/* <QualitiesListItem>
              <span className="icon">
                <Icon name="icon-code" />
              </span>
              <h3 className="title">Pixel perfect design to code</h3>
              <p>
                DRY aka Dont Repeat yourself, is an approach that aims, to turn
                repeated code or functions that serves the same goal, into
                reausable components or functions that can be executed as many
                times as desired, which gives developers the ability to maintain
              </p>
            </QualitiesListItem> */}
            <QualitiesListItem>
              <span className="icon">
                <Icon name="icon-code" />
              </span>
              <h3 className="title">Front-end development</h3>
              <p>
                Taking care of the front-end of things on your web pages, all
                the interactive functionalities and visual aspects and details,
                including refactoring existing front-end to match the final
                desired results. Using the best and modern techniques and web
                standard for better productivity and developer experience.
              </p>
            </QualitiesListItem>
            <QualitiesListItem>
              <span className="icon">
                <Icon name="icon-code" />
              </span>
              <h3 className="title">Wordpress development</h3>
              <p>
                Building fully functionnal Wordpress websites, including
                building or customizing themes, with ability to change and
                update the content right from a dashboard. All in place within
                the platform that covers 1/3 of the web industry.
              </p>
            </QualitiesListItem>
            <QualitiesListItem>
              <span className="icon">
                <Icon name="icon-code" />
              </span>
              <h3 className="title">eCommerce online stores</h3>
              <p>
                eCommerce websites, are one of the most kind of webistes I've
                built in my career so far. I've been using (but not limited to)
                Prestashop, a major open source solution for eCommerce. I'm also
                open to build with Shopify, WooCommerce and Headless eCommerce
                as well.
              </p>
            </QualitiesListItem>
            <QualitiesListItem>
              <span className="icon">
                <Icon name="icon-code" />
              </span>
              <h3 className="title">Website front redesign</h3>
              <p>
                Adipisicing elit. Saepe laborum beatae inventore praesentium
                recusandae quidem ducimus. Ab numquam debitis assumenda,
                delectus dignissimos ratione ut accusantium, totam dolorum
                cupiditate aut commodi.
              </p>
            </QualitiesListItem>
          </QualitiesList>
        </Container>
      </section>
    </>
  )
}

const QualitiesList = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 60px 60px;
  padding: 3rem 0 2rem;
`
const QualitiesListItem = styled.div`
  position: relative;
  /* background: #eaf1f657; */
  /* padding: 1.5rem; */
  /* border-radius: 6px; */
  /* padding-top: 4.5rem; */
  /* border: 2px solid #000; */
  /* box-shadow: 0px 6px 30px 3px #ececec; */
  /* cursor: pointer; */
  &::before {
    content: "";
    width: 60%;
    height: 90%;
    position: absolute;
    right: 0;
    /* z-index: -1; */
    bottom: -30px;
    background: ${props => props.theme.skinnyColor};
    /* opacity: .7; */
    border-radius: 5px;
  }
  & > * {
    position: relative;
  }
  .icon {
    width: 60px;
    height: 60px;
    display: inline-flex;
    position: relative;
    align-items: flex-end;
    margin-bottom: 1rem;
    filter: ${isIOS ? "none" : "url(#filter)"};
    svg {
      /* fill: ${props => props.theme.secondaryColor}; */
      .stroke {
        &[stroke] {
          stroke: ${props => props.theme.secondaryColor};
        }
        &[fill] {
          fill: ${props => props.theme.secondaryColor};
        }
      }
      .shape {
        /* fill: ${props => props.theme.isDark && props.theme.textColor}; */
        opacity: ${props => props.theme.isDark && ".4"};
      }
    }
    /* width: 120px;
    height: 120px;
    background: #eaf1f6;
    border: 2px solid #000;
    border-radius: 100%;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -60px); */
  }
  .title {
    color: ${props =>
      props.theme.isDark ? "#ebebeb" : props.theme.secondaryColor};
    font-size: 24px;
    font-family: var(--font-2);
    /* font-weight: 700; */
    letter-spacing: 0.3px;
    margin-bottom: 0.3rem;
    @media (max-width: 480px) {
      font-size: 22px;
    }
  }
  p {
    /* color: ${props => props.theme.textColor}; */
    color: ${props => !props.theme.isDark && props.theme.grey};
    letter-spacing: 0.4px;
    font-size: 14px;
    line-height: 1.5;
    /* font-weight: 400; */
    /* letter-spacing: 0.3px; */
    /* text-align: justify; */
    a {
      text-decoration: underline;
    }
  }
`
