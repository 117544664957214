import React from "react"
import styled from "styled-components"

export default function Title({ children }) {
  return <TitleEl> {children} </TitleEl>
}

const TitleEl = styled.div`
  /* color: ${props =>
    props.theme.isDark ? "#ebebeb" : props.theme.textColor}; */
  position: relative;
  display: inline-block;
  font-family: var(--font-2);
  /* font-weight: 700; */
  font-size: 40px;
  /* letter-spacing: 0.4px; */
  line-height: 1;
  margin-bottom: 1.5rem;
  z-index: 2;
  @media (max-width: 480px) {
    /* font-size: 26px; */
  }
  &::before {
    content: "";
    width: calc(100% + 12px);
    /* height: 40%; */
    height: 16px;
    background: ${props => props.theme.skinnyColor};
    position: absolute;
    left: 24px;
    bottom: -4px;
    z-index: -1;
    border: 1px solid
      ${props =>
        props.theme.isDark
          ? props.theme.textColor
          : props.theme.secondaryColor};
    border-width: 0 1px 1px 0;
    @media (max-width: 480px) {
      bottom: -10px;
      width: 100%;
      left: 0;
    }
  }
  &::after {
    content: "";
    width: 50px;
    height: 1px;
    background: ${props =>
      props.theme.isDark ? props.theme.textColor : props.theme.secondaryColor};
    position: absolute;
    right: 0;
    transform: translateX(calc(100% + 10px));
    bottom: 11px;
    @media (max-width: 480px) {
      /* width: 35px; */
      display: none;
    }
  }
`
