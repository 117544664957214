import React from "react"

import SEO from "../components/seo"
import Layout from "../components/layout"
import Intro from "../components/Intro"

import Services from "../components/Services"
import Technologies from "../components/Technologies"
import Bio from "../components/Bio"
import WorkList from "../components/WorkList"
import BlogList from "../components/BlogList"
import Contact from "../components/Contact"

const IndexPage = () => (
  <Layout>
    <SEO title="Muhamed" />
    <Intro />
    <div id="homepage-content">
      <Bio />
      <Services />
      <WorkList />
      <Technologies />
      {/* <BlogList /> */}
      <Contact />
    </div>
  </Layout>
)

export default IndexPage
