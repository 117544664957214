import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { isIOS } from "react-device-detect"

// import Image from "../utils/image"
import Container from "../styles/Container"
import Title from "../styles/Title"
import { PrimaryButton } from "../styles/Button"
import SocialLinks from "./partial/SocialLinks"
import { Image } from "../utils/image"

// Rusume PDF
// import PDF from "../data/muhamed-khubba-resume-v2.pdf"
import PDF from "../data/muhamed-hhobba-resume_compressed.pdf"

export default function Bio() {
  // console.log(PDF)

  let birth = new Date("1995-02-14T00:00:00")
  let diff = new Date(Date.now() - birth.getTime())
  let age = Math.abs(diff.getUTCFullYear() - 1970)

  // console.log(age)
  return (
    <BioSection id="bio">
      <Container>
        <BioGrid>
          <Image src="me" />
          <BioAbout>
            <div>
              <h2>
                <Title>
                  Hi, nice to have you <span>on board</span>
                </Title>
              </h2>
              <p>
                Hi, My name is Muhamed and I am a {age} years old front-end
                developer based in Morocco. I'm a learner, open-minded thinker
                and a tech believer, I've been building for the web over the
                last couple of years within a digital solutions agency.
              </p>
              <p>
                Learning by building user interfaces, researching and solving
                problems on a daily basis along with sharing ideas with the team
                members and the web development community, helped me develop a
                solid knowledge about various web development key principles.
              </p>
              <p>
                I've always been someone who asks tons of questions about how
                things work and why they don’t work differently. Thus I am fully
                open to learn new web building concepts, techniques and tools.
                In other, not cosmic words, I'm a big fan of the web industry
                technologies.
                {/* whether it's dedicated to the browser, the mobile
                or even the desktop */}
              </p>
              <p>
                Some trivia about me : I'm one of the biggest football
                enthusiasts you can ever know, I like EDM music, I used to draw
                awesome stuff when I was younger, And I also used to use weird
                stuff to hunt fishes and sea animals.
              </p>
            </div>
            <div className="socialWrapper">
              <SocialLinks />
              <PrimaryButton as="a" href={PDF} target="_blank">
                View resume
              </PrimaryButton>
            </div>
          </BioAbout>
        </BioGrid>
      </Container>
    </BioSection>
  )
}

const BioSection = styled.section`
  position: relative;
  overflow: hidden;
  &::before {
    content: "About";
    font-size: 20rem;
    font-family: var(--font-2);
    font-weight: 900;
    position: absolute;
    z-index: -1;
    letter-spacing: 2rem;
    left: 39%;
    bottom: 0;
    /* color: rgb(236 236 236 / 58%); */
    color: ${props => props.theme.shade};
    line-height: 1;
  }
`
const BioGrid = styled.div`
  display: grid;
  grid-template-columns: 1.25fr 2fr;
  grid-gap: 30px;
  align-items: flex-start;
  @media (max-width: 767px) {
    grid-template-columns: 1fr;
    grid-gap: 0;
    .vintage-svg__outer {
      z-index: -1;
    }
  }
  img {
    /* filter: grayscale(1); */
    filter: ${isIOS ? "none" : "url(#filter)"};
    border-radius: 2px;
    object-position: top center !important;
  }
  .gatsby-image-wrapper {
    position: relative;
    &::before {
      content: "";
      position: absolute;
      z-index: 2;
      inset: 0;
      background-color: rgba(0, 0, 0, 0.1);
    }
    &:hover {
      &::before {
        background-color: rgba(0, 0, 0, 0);
      }
    }
  }
`
const BioAbout = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 4;
  @media (max-width: 557px) {
    margin-top: -100px;
    padding: 0 1rem 2rem;
    background: linear-gradient(to top, #000 90%, transparent);
    border: 1px solid ${props => props.theme.darkBorder};
    border-top: none;
    border-radius: 2px;
    h2 > div {
      margin-bottom: 1rem;
      &::before {
        display: none;
      }
    }
  }
  .socialWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 30px;
    @media (max-width: 557px) {
      display: grid;
      grid-gap: 1.5rem;
      justify-content: center;
    }
  }
  p {
    /* line-height: 1.6; */
    text-align: justify;
    /* color: #505050; */
    /* color: ${props => props.theme.textColor}; */
    &:not(last-child) {
      margin-bottom: 1rem;
    }
    a {
      color: ${props => props.theme.secondaryColor};
      /* text-decoration: underline; */
    }
  }
`
