import styled from "styled-components"
import { Link } from "gatsby"
const radius = "6px"
const Item = styled(Link)`
  display: flex;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-top: 24px;
  /* background: var(--skyblue); */
  background: ${props => props.theme.skinnyColor};
  border-radius: ${radius};
  transform-style: preserve-3d;
  .gatsby-image-wrapper,
  img {
    transition: 0.3s !important;
  }
  img {
    transform: translateX(-24px);
    border-radius: ${radius};
    background: ${props => props.theme.mainColor};
    /* border: 2px solid ${props =>
      props.theme.isDark && props.theme.skinnyColor}; */
    border: 1px solid #ffffff12;
    /* filter: url("#filter"); */
    filter: grayscale(1);
  }
  &:hover {
    .gatsby-image-wrapper {
      // transform: translateX(-24px) translateZ(60px);
      transform: translateX(0) translateZ(60px);
      img {
        filter: grayscale(0);
      }
    }
  }
  .gatsby-image-wrapper {
    overflow: visible !important;
  }
`
const ItemCaption = styled.div`
  padding: 20px 20px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  h3 {
    font-size: 24px;
    margin: 0 0 5px;
  }
  p {
    /* font-weight: 14px; */
    line-height: 1.4;
  }
`
const ItemDate = styled.div`
  font-size: 14px;
  margin-left: auto;
  margin-top: 0.65rem;
  opacity: 0.65;
`

const LastItem = styled(Link)`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    .inner {
      border: 2px dashed ${({ theme }) => theme.textColor};
      border-radius: 0;
      color: ${({ theme }) => theme.secondaryColor};
    }
  }
`
const LastItemInner = styled(Link)`
  transform-style: preserve-3d;
  width: 100%;
  height: calc(75%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-self: center;
  padding: 0px;
  color: ${({ theme }) => theme.textColor};
  background: ${props => props.theme.mainColor};
  border: 2px solid ${({ theme }) => theme.skinnyColor};
  border-radius: ${radius};
  margin-left: -24px;
  position: relative;
  &:hover {
    & > div {
      transform: translateZ(60px);
    }
  }
  & > div {
  transition: 0.3s;
  }
  .icon {
    width: 80px;
    height: 80px;
    background: #000;
    border-radius: 100%;
    margin: 0 auto;
    border: 2px solid currentColor;
  }
  p {
    display: block;
    text-align: center;
    margin: 15px 0 0rem;
    font-size: 14px;
  }
  button {
    margin-top: .5rem;
    color: inherit;
    /* border-color: ${props => props.theme.textColor}; */
    border-color: inherit !important;
    transition: 0.3s;
  }
`

export { Item, ItemCaption, LastItem, ItemDate, LastItemInner }
