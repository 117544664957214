import React, { useEffect } from "react"
import { createGlobalStyle } from "styled-components"

import SwiperCore, { Scrollbar } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/swiper-bundle.css"

import Container from "../styles/Container"
import Title from "../styles/Title"
import WorkItem from "./WorkItem"
import LearnMore from "../styles/LearnMore"
import TitleWrapper from "../styles/TitleWrapper"
import ItemList from "../styles/ItemList"
import { Alert } from "../styles/Alert"
import { LastItem, LastItemInner } from "../styles/Card"
import { PrimaryButton } from "../styles/Button"
import TiltCard from "../components/partial/TiltCard"
// Data
import workAgency from "../data/work-sample-pro"
import workFreelance from "../data/work-sample-personal"

export default function WorkList() {
  useEffect(() => {
    SwiperCore.use([Scrollbar])
  }, [])

  const Projects = [...workFreelance, ...workAgency]
  const SelectedProjects = Projects.filter(
    project => project.status === "published"
  )
  console.log(SelectedProjects)
  return (
    <section id="work">
      <GlobalSwiperStyle />
      <Container>
        <TitleWrapper>
          <h2>
            <Title>
              Selection of work{" "}
              {/* <span role="img" aria-label="Emoji icon">
                🎨
              </span> */}
            </Title>
          </h2>
          <LearnMore to="/">Learn more</LearnMore>
        </TitleWrapper>
        <p>
          During the past few years as a developer within a web development
          agency, I've been creating interactive accessible web experiences for
          clients in various fields, as the front-end consultant and devoloper,
          in a productive colaboration with a team of back-end developers,
          designers, and content creators.
        </p>
        <Alert>
          <p>
            NOTE: Due to confidentiality policies, I'm showcasing exclusively
            few projects that I have built recently as a freelancer
          </p>
        </Alert>
      </Container>
      <ItemList>
        <Container>
          <Swiper
            breakpoints={{
              375: {
                slidesPerView: 1,
              },
              480: {
                slidesPerView: 2,
                spaceBetween: 40,
              },
              768: {
                slidesPerView: 2,
                spaceBetween: 60,
              },
              1024: {
                slidesPerView: 3,
                spaceBetween: 60,
              },
            }}
            spaceBetween={60}
            scrollbar
          >
            {SelectedProjects.map(project => {
              // console.log(project)
              return (
                <SwiperSlide key={project.id}>
                  <WorkItem
                    image={project.image}
                    title={project.title}
                    prodcutionDate={project.prodcutionDate}
                    content={project.descriptionShort}
                    url={project.url}
                  />
                </SwiperSlide>
              )
            })}
            <SwiperSlide>
              <LastItem as="a" href="mailto:maeid.kh@gmail.com">
                {/* <div className="outer"> */}
                <LastItemInner className="inner" as={TiltCard}>
                  <div>
                    <div className="icon">
                      <svg
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M5,13V12H11V6H12V12H18V13H12V19H11V13H5Z" />
                      </svg>
                    </div>
                    <p>Your next project ?</p>
                    <PrimaryButton>Drop me a line</PrimaryButton>
                  </div>
                </LastItemInner>
                {/* </div> */}
              </LastItem>
            </SwiperSlide>
          </Swiper>
        </Container>
      </ItemList>
    </section>
  )
}

export const GlobalSwiperStyle = createGlobalStyle`
  .swiper-slide {
    height: auto;
  }
`
