import React from "react"

const FormInput = props => {
  return (
    <>
      {props.textarea ? (
        <>
          <textarea {...props}></textarea>
          {props.placeholder && <label htmlFor=""> {props.placeholder} </label>}
        </>
      ) : (
        <>
          <input {...props} />
          {props.placeholder && <label> {props.placeholder} </label>}
        </>
      )}
    </>
  )
}

export default FormInput
